import './styles/Header.css';
import AccountMenu from './AccountMenu';
import { Link } from "react-router-dom";
import { useLayoutEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from 'react';
import { IsDev, GetAccessToken } from './Utilities';
import RedditPixel from 'react-reddit-pixel';

ReactGA.initialize('G-P1CP4P4GZ6');

function Header({ status, setStatus }) {
    const [size, setSize] = useState([0, 0]);

    const location = useLocation();

    const [queryParams] = useSearchParams();

    useEffect(() => {
        GetAccessToken();
        RedditPixel.init('a2_f8s8vf4d4enx');
        RedditPixel.pageVisit();
    }, []);

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") return;
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    useLayoutEffect(() => {

        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
    }, []);


    return (
        <header className="headerParentList">
            <ul className="headerLine">
                <li className="logo"><a href='/'><img src="LudareLogoTransparent.png"/></a></li>
                {size[0] >= 1000 && <ExpandedHeader status={status} setStatus={setStatus} />}
                {size[0] < 1000 && <CollapsedHeader status={status} setStatus={setStatus} />}
            </ul>
        </header>
    );
}

function ExpandedHeader({ status, setStatus }) {
    return (
        <div className="expandedHeaderParent">
            <ul className="headerButtons">
                <li className="contactLink"><a href="/contactUs">Contact Us</a></li>
                <li className="libraryLink"><a href="/Library">Library</a></li>
                <li className="studiosLink"><a href="/studios">Studios</a></li>
                <li className="playersLink"><a href="/players">Players</a></li>
                <li className="homeLink"><a href="/">Home</a></li>
            </ul>
            <div className="account"><AccountMenu status={status} setStatus={setStatus} collapsed={false} /></div>
        </div>
    );
}


function CollapsedHeader({ status, setStatus }) {
    const [hovered, setHovered] = useState(false);

    function SignOut() {

        if (Cookies.get("LudareRefreshToken") === undefined) return;

        GetAccessToken().then((token) => {

            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'RefreshToken': Cookies.get("LudareRefreshToken"), 'AccessToken': token })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/Logout', reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    //if (data.AccessToken === "" || data.AccessToken === undefined) return;
                    Cookies.remove("LudareAccessToken");
                    Cookies.remove("LudareRefreshToken");
                    setStatus(false);
                });
        });
    };

    return (
        <div className="collapsedDiv"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <p className="collapsedMenu">Menu</p>
            {hovered && <ul className="collapsedMenuList">
                <li className="collapsedStudiosLink"><a href="/studios">Studios</a></li>
                <li><hr /></li>
                <li className="collapsedPlayerLink"><a href="/players">Players</a></li>
                <li><hr /></li>
                <li className="collapsedContactLink"><a href="/contactUs">Contact Us</a></li>
                <li><hr /></li>
                <li className="collapsedLibraryLink"><a href="/library">Library</a></li>
                <li><hr /></li>
                <li className="collapsedSignUpLink"><AccountMenu status={status} setStatus={setStatus} collapsed={true} /></li>
                
                
            </ul>}
        </div>
    );
}

//<li className="homeLink"><a href="/">Home</a></li>
//<li className="collapsedHomeLink"><a href="/">Home</a></li>
//<li><hr /></li>

export default Header;

/*{(status === false && process.env.NODE_ENV === "development") && <div>
                    <li className="account"><AccountMenu status={status} setStatus={setStatus} /></li>
                </div>}*/
