// JavaScript source code
import "./styles/GameDatabaseEditor.css";
import { useState, useRef, useEffect } from 'react';
import AWS from "aws-sdk";

function GameDatabaseEditor() {
    const [gameInfo, setGameInfo] = useState([]);

    useEffect(() => {
        const gameDataOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/AdminGetGameDatabase', gameDataOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameInfo(data.message);
                    console.log(data.message);
                }
            });
    }, []);

    return (
        <div>
            {gameInfo.length > 0 && gameInfo.map((info, index) => {
                return <GameDataTable gameData={info} />
            })}
        </div>
    );
}

function GameDataTable({ gameData }) {
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [steamLink, setSteamLink] = useState("");
    const [epicLink, setEpicLink] = useState("");
    const [tagIds, setTagIds] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [desc, setDesc] = useState("");

    useEffect(() => {
        setName(gameData.Name);
        setStatus(gameData.Status);
        setSteamLink(gameData.SteamLink);
        setEpicLink(gameData.EpicLink);
        setTagIds(gameData.TagId);
        setPhotoUrl(gameData.PhotoUrl);
        setBackgroundUrl(gameData.BackgroundUrl);
        setDesc(gameData.Description);

        console.log(gameData.Status);
    }, [gameData]);

    function UploadKeyImage(event) {
        setPhotoUrl('games/' + gameData.ID + '/keyArt.png');
        UploadImage(gameData.ID, "keyArt", event.target.files[0]);
    }

    function UploadBackgroundImage(event) {
        setBackgroundUrl('games/' + gameData.ID + '/background.png');
        UploadImage(gameData.ID, "background", event.target.files[0]);
    }

    function UploadImage(gameId, type, fileContents) {
        //setUpload(false);
        const name = `games/${gameId}/${type}.png`;
        setPhotoUrl(name);

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        })

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: fileContents,
        }

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log("Updating " + parseInt((evt.loaded * 100) / evt.total) + "%");
            }).promise().then((err, data) => {
                console.log("File Uploaded");
                //setUpload(true);
            });

    }

    function SubmitChanges(event) {
        event.preventDefault();

        const gameDataOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'gameId': gameData.ID,
                'gameName': name,
                'description': desc,
                'photoUrl': photoUrl,
                'steamLink': steamLink,
                'epicLink': epicLink,
                'tagId': tagIds,
                'backgroundUrl': backgroundUrl,
            })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', gameDataOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    console.log("Updated");
                }
            });
    }

    return (
        <div className="databaseEntryContainer">
            <form className="gameEntryForm" onSubmit={SubmitChanges}>
                <input type="text" defaultValue={gameData.ID} readOnly />
                <input type="text" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                <input type="text" defualtValue={status} onChange={(e) => setStatus(e.target.value)} />
                <input type="text" defaultValue={steamLink} onChange={(e) => setSteamLink(e.target.value)} />
                <input type="text" defaultValue={epicLink} onChange={(e) => setEpicLink(e.target.value)} />
                <input type="text" defaultValue={tagIds} onChange={(e) => setTagIds(e.target.value)} />
                <input type="text" defaultValue={desc} onChange={(e) => setDesc(e.target.value)} />
                <div className="gamePhotoUpload">
                    <input type="file" onChange={UploadKeyImage} />
                    <img src={process.env.REACT_APP_S3_BASE_URL + photoUrl} />
                </div>
                <div className="gamePhotoUpload">
                    <input type="file" onChange={UploadBackgroundImage} />
                    <img src={process.env.REACT_APP_S3_BASE_URL + backgroundUrl} />
                </div>
                <input type="submit" />
            </form>
        </div>
    );
}

export default GameDatabaseEditor;