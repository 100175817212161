// JavaScript source code
import "./styles/GameDownloads.css";
import { useEffect, useState, useRef } from 'react';
import { GetAccessToken } from "./Utilities";
//import FetchAccountInfo from './AccountPage';

function FetchAccountInfo(setAccountInfo) {

    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetAccountInfo";
    //console.log(Cookies.get("AccessToken"));
    GetAccessToken().then((token) => {
        //console.log(Cookies.get("AccessToken"));
        //console.log(token);


        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'token': token })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => { setAccountInfo(data.message); });
    });
}

function GameDownloads() {
    const [gameList, setGameList] = useState([]);
    const [accountInfo, setAccountInfo] = useState();

    useEffect(() => {
        FetchAccountInfo(setAccountInfo);
    }, []);

    useEffect(() => {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetGamesLibrary";
        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => { setGameList(data.message) });
        
    }, [accountInfo]);

    return (
        <div className="downloadPage">
            {accountInfo !== null && gameList.map((game, index) => {
                return <GameDownloadEntry gameInfo={game} />;
            })}
            {accountInfo === null &&
                <p className="notSignedInMessage">Please sign in or activate your account</p>
            }
        </div>
    );
}

function GameDownloadEntry({gameInfo}) {

    return (
        <div>
            <h1 className="gameName">{gameInfo.Name}</h1>
            {   gameInfo.PublicBuildLinks !== null &&
                gameInfo.PublicBuildLinks.map((link, index) => {
                    return <a href={link} download />;
                })
            }
        </div>
    );
};

export default GameDownloads;