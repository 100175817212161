// JavaScript source code
import "./styles/AccountPage.css";
import { Navigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import crypto from 'crypto-js';
import Cookies from 'js-cookie';
import { useSearchParams } from "react-router-dom";
import LudareCheckoutForm from "./CheckoutForm";
import Popup from 'reactjs-popup';
import SetupSubscription from "./SetupSubscription";
import LoadingPopup from './LoadingPopup';
import { GetAccessToken, CheckUsername, UploadPictureLink} from "./Utilities";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ReactGA from 'react-ga';
import AWS from "aws-sdk";
//import loadingGif from '/public/';

ReactGA.initialize('G-P1CP4P4GZ6');

function AccountPage({ status }) {
    const [accountInfo, setAccountInfo] = useState();
    const [queryParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState("");
    const [currDevId, setCurrDevId] = useState();
    const [gameArray, setGameArray] = useState([]);
    const [devArrayCount, setDevArrayCount] = useState(0);
    const [developerArray, setDeveloperArray] = useState([]);
    const [currGame, setCurrGame] = useState();
    const tempDevArray = useRef([]);
    const targetDevID = useRef();
    //const tempGameArray = useRef([]);

    function SubscriptionClick() {
        setCurrDevId(undefined);
        setPageStatus("Subscription");
    }

    function LinkAccountsClick() {
        setCurrDevId(undefined);
        setPageStatus("LinkAccounts");
    }

    function AccountClick() {
        setCurrDevId(undefined);
        setPageStatus("Account");
    }

    useEffect(() => {
        

        FetchAccountInfo(setAccountInfo);

        if (queryParams.get("steamID") !== undefined && queryParams.get("steamID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("steamID"), 'type': "steam" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (queryParams.get("epicID") !== undefined && queryParams.get("epicID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("epicID"), 'type': "epic" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (queryParams.get("loadPage") !== null) {
            setPageStatus(queryParams.get("loadPage"));
        }

        if (queryParams.get("loadDev") !== null) {
            targetDevID.current = queryParams.get("loadDev");
        }

        if (queryParams.get("loadGame") !== null) {
           setCurrGame(queryParams.get("loadGame"));
        }

    }, [queryParams]);

    useEffect(() => {
        if (accountInfo === undefined || accountInfo === null) {
            return;
        }

        if (accountInfo.DeveloperOn !== null && accountInfo.DeveloperOn.length > 0) {

            var devIds = JSON.parse(accountInfo.DeveloperOn);

            if (devIds !== undefined && devIds !== null) {
                devIds.map((developerId, index) => {
                    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetDeveloperInfo";

                    const reqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'developerId': developerId })
                    };

                    fetch(URL, reqOptions)
                        .then((res) => res.json())
                        .then((data) => {

                            var newDevArray = tempDevArray.current;

                            var lastIndex = -1;

                            if (data.message === undefined) {
                                return;
                            }

                            if (targetDevID.current === data.message.DeveloperID) {
                                setCurrDevId(targetDevID.current);
                            }

                            for (var i = 0; i < newDevArray.length; i++) {
                                if (newDevArray[i].DeveloperID === data.message.DeveloperID) {
                                    lastIndex = i;
                                }
                            }

                            if (lastIndex === -1) {
                                newDevArray = [...newDevArray, data.message];
                            }

                            tempDevArray.current = newDevArray;

                            setDeveloperArray(tempDevArray.current);
                            setDevArrayCount(newDevArray.length);
                        });
                });
            }
        }
    }, [accountInfo])

    useEffect(() => {
        setCurrDevId(targetDevID.current);
    }, [targetDevID, developerArray]);

    useEffect(() => {

        var devInfo;
        for (var i = 0; i < developerArray.length; i++) {
            if (currDevId !== undefined && currDevId.toString() === developerArray[i].DeveloperID.toString()) {
                devInfo = developerArray[i];
                break;
            }
        }

        var newGamesArray = [];

        if (devInfo === undefined || devInfo === null) return;

        var games = JSON.parse(devInfo.Game);

        if (games !== undefined && games !== null) {
            games.map((gameID, Index) => {

                if (newGamesArray.indexOf(gameID) === -1) {
                    newGamesArray = [...newGamesArray, gameID];

                }
                return true;
            });
        }

        setGameArray(newGamesArray);

    }, [currDevId, developerArray])

    function DeveloperListArray() {

        return (
            <div className="devArrayDiv">
                {devArrayCount > 0 && gameArray.map !== null && (developerArray.map((DeveloperInfo, index) => (
                    <DeveloperListItem developerInfo={DeveloperInfo} setPage={setPageStatus} setDevId={setCurrDevId} setCurrGame={setCurrGame} currDevId={currDevId} />
                )))}
            </div>
        );
    }

    return (
        <div className="accountPage">
            {!status && <Navigate to='/' />}
            <ul className="sideMenu">
                <li><p className="personalHeader">Personal</p></li>
                <ul className="personalMenu">
                    <li><button value="Account" onClick={AccountClick} style={{ background: ((pageStatus === "" || pageStatus === "Account") ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)' )}}>Account</button></li>
                    <li><button value="LinkAccounts" onClick={LinkAccountsClick} style={{ background: (pageStatus === "LinkAccounts" ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }}>Link Accounts</button></li>
                    <li><button value="Subscription" onClick={SubscriptionClick} style={{ background: (pageStatus === "Subscription" ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }}>Subscription</button></li>
                </ul>
                <li><hr/></li>
                <li><p className="developerHeader">Developer</p></li>
                <ul className="developerMenu">
                    {accountInfo !== undefined && devArrayCount > 0 && <DeveloperListArray />}
                    <DeveloperListItem setPage={setPageStatus} setDevId={setCurrDevId} setCurrGame={setCurrGame} currDevId={currDevId} setCurrDevId={setCurrDevId} currPage={pageStatus} />
                </ul>
            </ul>
            <div className="verticalBreak" />
            <div className="infoPane">
                {(pageStatus === "" || pageStatus === "Account") && <BasicAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "Subscription" && <SubscriptionInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "LinkAccounts" && <ConnectedAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "Developer" && <DeveloperPage devId={currDevId} accountInfo={accountInfo} gameArray={gameArray} devArray={developerArray} currGame={currGame} setCurrGame={setCurrGame} /> }
                {pageStatus === "NewDeveloper" && <NewDeveloperPage accountInfo={accountInfo} />}
            </div>
        </div>
    );
}

export default AccountPage;

function BasicAccountInfo({ accountInfo, setAccountInfo }) {
    //console.log(accountInfo);
    const [displayName, setDisplayName] = useState();
    const [usernameCheck, setUsernameCheck] = useState(undefined);
    const [usernameCheckRes, setUsernameCheckRes] = useState("");
    var tempDisplay = "";

    useEffect(() => {
        if (accountInfo !== null && accountInfo !== undefined) {
            setDisplayName((accountInfo.DisplayName !== null && accountInfo.DisplayName !== undefined) ? accountInfo.DisplayName : accountInfo.Username);
        }
    }, [accountInfo]);

    function UpdateDisplayName() {
        if (usernameCheck === false) return;

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': accountInfo.username, 'newName': tempDisplay })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateDisplayName', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                /*if (data.success === false) {
                    return;
                }
                if (data.result === true) {
                    resultString("Username is not taken");
                }
                else {
                    resultString("Username is already taken");
                }*/

                FetchAccountInfo(setAccountInfo);
            });
    }

    function CheckCurrentUsername(event) {
        event.preventDefault();

        CheckUsername(tempDisplay, setUsernameCheck, setUsernameCheckRes);
    }
    
    const ModifyUsernamePopup = ({ accountInfo }) => (
        <Popup trigger={<button className="modifyUsername">Update</button>} modal nested lockScroll >
            {close => (
                <form className="displayNameEditForm">
                    <label className="editNameLabel" htmlFor="displayName">Display Name:</label>
                    <input type="text" id="displayName" name="displayName" onChange={(e) => { e.preventDefault(); tempDisplay = e.target.value; }} />
                    <div className="confirmUsername"><button className="checkName" onClick={CheckCurrentUsername}>Check DisplayName</button> {usernameCheck !== undefined && <p className="usernameCheckRes" style={{ color: (usernameCheck === true) ? "lightgreen" : "red" }}>{usernameCheckRes}</p>} </div>
                    <div className="displayNameEditButtons"> <button className="confirmEdit" onClick={UpdateDisplayName}>Update Display Name</button> <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button> </div>
                </form>
            )}
        </Popup>
    );

    return (
        <div>
            {accountInfo !== undefined && (<div className="accountInfoPane" >
                <div className="usernameBlock">
                    <p className="accountUsername">Username:</p>
                    <div className="modifyUsernameBlock">
                        <p> {displayName} </p>
                        
                    </div>
                </div>
                <hr />
                <div className="emailBlock">
                    <p className="accountEmail">email:</p>
                    <p className="emailField">{accountInfo.Email}</p>
                </div>
            </div>)}
        </div>

    );
}

//<ModifyUsernamePopup accountInfo={accountInfo } />

function ConnectedAccountInfo({ accountInfo, setAccountInfo }) {
    //console.log(accountInfo.EpicID);

    useEffect(() => {
        
    }, []);

    return (
        <div className="accountLinks">
            {accountInfo !== undefined && <div className="steamLink">
                <a href={process.env.REACT_APP_NODE_ADDRESS + "/auth/steam?userID=" + accountInfo.LudareID}>
                    <img className="platformImg" src="SteamLoginImage.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Steam </p> <br />
                <p style={{ color: (accountInfo.SteamID !== null && accountInfo.SteamID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.SteamID && accountInfo.SteamID !== undefined) !== null ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="epicLink">
                <a href={"https://www.epicgames.com/id/authorize?client_id=xyza7891yUZ9T5c0Ei459DIUyKn7vglR&response_type=code&scope=basic_profile&redirect_uri=https://www.devpowered.com:8000/auth/epic"}>
                    <img className="platformImg" src="epic_games_black_logo.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Epic </p> <br />
                <p style={{ color: (accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="googleLink">
                <a href={"https://accounts.google.com/o/oauth2/v2/auth?client_id=289770615480-dk7l2cnsr9jqecgtidmtbfor5gcalagh.apps.googleusercontent.com&response_type=code&scope=openid%20email&redirect_uri=" + process.env.REACT_APP_BASE_ADDRESS + "/GoogleReturn"}>
                    <img src="Android.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Android </p> <br />
                <p style={{ color: (accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
        </div>

    );
}


function SubscriptionInfo({ accountInfo, setAccountInfo }) {
    const [selectedPayment, setSelectedPayment] = useState(undefined);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showPayments, setShowPayments] = useState(false);
    const [subscription, setSubscription] = useState(undefined);
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [newSubscriptionAmount, setNewSubscriptionAmount] = useState(0);
    const [subscriptionPopupClosed, setSubscriptionPopupClosed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadTillValid, setLoadTillValid] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    var tempSubscription;

    useEffect(() => {
        if (accountInfo !== undefined) {
            GetSubscription();
            GetPaymentMethods();
        }
    }, [accountInfo]);

    useEffect(() => {
        console.log("Sub Updated");

        if (subscription !== undefined) {
            setLoading(false);
            GetSubscriptionPrice();
            for (var i = 0; i < paymentMethods.length && subscription !== null && subscription !== undefined; i++) {
                if (paymentMethods.id === subscription.default_payment_method) {
                    setSelectedPayment(paymentMethods[i]);
                }
            }
        }
        else {
            if (loadTillValid === true) {
                GetSubscription();
            }
            console.log("No Subscription Set");
        }
    }, [subscription]);

    function GetPaymentMethods() {
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetPaymentMethods", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setPaymentMethods(data.paymentMethods);
                    if (data.paymentMethods.length > 0) {
                        setSelectedPayment(data.paymentMethods[0]);
                    }
                }
                else {
                    setSelectedPayment(undefined);
                }
            });
    }

    function GetSubscription() {
        console.log("Get Sub");
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscription(data.subscription);
                }
                else {
                    setSubscription(null);
                }
                console.log("Got Sub");
            });
    }

    function LoadSubscriptionTilValid() {
        var count = 0;
        setLoading(true);
        setLoadTillValid(true);
        GetSubscription();
    }

    function GetSubscriptionPrice() {
        if (subscription === null || subscription === undefined) return;
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'price': subscription.items.data[0].price.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetStripePrice", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscriptionAmount(data.price.unit_amount);
                    setNewSubscriptionAmount(data.price.unit_amount);
                }
            });
    }

    function PaymentMenuOnClick() {
        setShowPayments(!showPayments);
    }

    function PaymentSelected(paymentMethod) {
        setSelectedPayment(paymentMethod);
        setShowPayments(false);
    }

    function NewPaymentSelected() {
        setPopupOpen(true);
        setSelectedPayment(undefined);
        setShowPayments(false);
    }

    function DeletePaymentMethod() {
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'payment': selectedPayment.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/RemovePayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    GetPaymentMethods();
                }
            });
    }

    function StartSubscription(price, paymentMethod) {

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId, 'price': price, "paymentSource": paymentMethod.id }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CreateSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    var callback = function () {
                        GetPaymentMethods();
                        LoadSubscriptionTilValid();
                    }

                    ReactGA.gtag('event', 'conversion_event_subscribe_paid', {
                        'event_callback': callback,
                        'event_timeout': 2000,
                    });
                }
            });
    }

    function CheckAndMakeSubscription() {
        if (selectedPayment === undefined || newSubscriptionAmount < 2.00) {
            return;
        }
        else {
            //console.log(selectedPaymentAmount);
            StartSubscription(newSubscriptionAmount, selectedPayment);
        }
        setSubscriptionPopupClosed(true);
    }

    function IsSubActive() {
        return (subscription !== undefined && subscription !== null && subscription.status === "active");
    }

    function GetNextBillDate() {
        const billDate = new Date(subscription.current_period_end * 1000);
        return billDate.toLocaleDateString();
    }

    function RecommendedSubButtons({ setSubValue1, setSubValue2, setSubValue3 }) {
        return (
            <div className="possibleSubButtons">
                <button className="recommendedAmmount1 accountPageButton" onClick={setSubValue1}> 3.00 </button>
                <button className="recommendedAmmount2 accountPageButton" onClick={setSubValue2}> 5.00 </button>
                <button className="recommendedAmmount3 accountPageButton" onClick={setSubValue3}> 10.00 </button>
                <SetSubscriptionPopup />
            </div>
        );
    }

    function CancelSubscription() {
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'subscription': subscription.id }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CancelSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscription(undefined);
                }
            });
    }

    function SetDefaultPayment(paymentMethod) {

        if (selectedPayment === null || selectedPayment === undefined) return;

        var postData = { customer: accountInfo.StripeId, paymentMethod: selectedPayment.id };

        if (subscription !== null && subscription !== undefined) {
            postData = { customer: accountInfo.StripeId, paymentMethod: selectedPayment.id, subscription: subscription.id };
        }

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/SetDefaultPayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {

            });
    }

    function ClosePaymentPopup() {
        setPopupOpen(false);
    }

    function OpenPaymentPopup() {
        setPopupOpen(true);
    }

    const UpdateSubscriptionAmount = (event) => {
        event.preventDefault();
        setNewSubscriptionAmount(tempSubscription * 100);
    }

    const UpdateSubscription = (event) => {
        event.preventDefault();

        if (subscription === null || subscription === undefined) return;

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ price: newSubscriptionAmount , subscriptionId: subscription.id}),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/UpdateSubscriptionAmount", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setSubscriptionAmount(newSubscriptionAmount);
                }
            });
        
    }

    const AddPaymentPopup = ({ accountInfo }) => (
        <Popup open={popupOpen} onClose={ClosePaymentPopup} modal nested lockScroll>
            <div className="checkoutPopUp">
                <LudareCheckoutForm accountInfo={accountInfo} />
            </div>
        </Popup>
    );

    function RecommendedSub1() {
        setNewSubscriptionAmount(300);
    }

    function RecommendedSub2() {
        setNewSubscriptionAmount(500);
    }

    function RecommendedSub3() {
        setNewSubscriptionAmount(1000);
    }

    const SetSubscriptionPopup = ({ accountInfo }) => (
        <Popup trigger={<button className="editSubscriptionButton accountPageButton">Custom</button>} modal nested lockScroll >
            {close => (
                <div className="subscriptionPaymentPopup">
                    <h3 className="subscriptionTitle">Monthly Subscription</h3>
                    <label className="subscriptionSubTitle" htmlFor="subAmount">Current Subscription Amount</label> <br />
                    <span className="subSpan">
                        <p className="currencySymbol">$</p>
                        <input type="number" step=".01" min="2.00" className="currSubscriptionAmountSetup" onChange={(e) => (tempSubscription = e.target.value)} defaultValue={newSubscriptionAmount / 100} />
                    </span>
                    <div className="subscriptionEditButtons">
                        <button className="confirmEdit" onClick={UpdateSubscriptionAmount}>Set Subscription</button>
                        <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button>
                    </div>
                </div>
            )}
        </Popup>
    );

    return (
        <div>
            {loading === true &&
                <LoadingPopup/>
            }
            {loading === false &&
            <div className="subscriptionMenu">
                <div className="subscriptionStatus">
                    <h3 className="subStatusTitle">Subscription Status</h3>
                    <div>
                        <p className="subStatusTitle">Status:</p>
                        <p className="subStatusResult" style={{ color: IsSubActive() ? "lightgreen" : "lightred" }}>{IsSubActive() ? "Active" : "Inactive"}</p>
                    </div>
                    {IsSubActive() && <div>
                        <p className="subStatusDateTitle">Next Billing Date:</p>
                        <p className="subStatusDate">{GetNextBillDate()}</p>
                    </div>}
                </div>
                <hr />
                <div>
                    <div className="creditInfoForm">
                        <h3 className="paymentMethodTitle">Payment Method</h3>
                        <div className="paymentMethod">
                            <button className="paymentMenu" onClick={PaymentMenuOnClick}>
                                {selectedPayment === undefined && <p className="newPayment">Add New Payment Method</p>}
                                {selectedPayment !== undefined && <PaymentMethodListItem paymentMethod={selectedPayment} clickable={false} subscription={subscription} />}
                            </button>
                                <AddPaymentPopup className="accountPageButton" accountInfo={accountInfo} /> 
                                {selectedPayment === undefined && <span className="addNewPaymentSpan"> <button className="addNewPayment accountPageButton" onClick={OpenPaymentPopup}>Add</button> </span>}
                                {selectedPayment !== undefined && <span className="paymentModificationButtons"> <button className="setDefaultPayment accountPageButton" onClick={SetDefaultPayment}>Set Default</button> <button className="deletePayment accountPageButton" onClick={DeletePaymentMethod}>Delete</button> </span>}
                        </div>
                        {showPayments && <div className="paymentMethodsDropdown">
                            {paymentMethods.map((paymentMethod, index) => (
                                <div>
                                    <PaymentMethodListItem paymentMethod={paymentMethod} selected={PaymentSelected} clickable={true} breakAfter={true} subscription={subscription} />
                                    <hr />
                                </div>
                            ))}
                            <button className="newPaymentMenu" onClick={NewPaymentSelected}>+ Add New Payment Method</button>
                        </div>}
                    </div>
                    <hr />
                    <div className="subscriptionPaymentMenu">
                        <h3 className="subscriptionTitle">Monthly Subscription</h3>
                        <label className="subscriptionSubTitle" htmlFor="subAmount">Current Subscription Amount</label> <br />
                        <p className="currSubscriptionAmount">${parseFloat(newSubscriptionAmount / 100).toFixed(2)}</p>
                        <RecommendedSubButtons setSubValue1={RecommendedSub1} setSubValue2={RecommendedSub2} setSubValue3={RecommendedSub3} />
                        <p className="minPayment">Minimum Payment: $2</p>
                        <p className="taxPayment">Tax added on subscription change.</p>
                        </div>
                    <hr />
                    {IsSubActive() && <div>
                        <button className="deactivateSubscription accountPageButton" onClick={CancelSubscription}>Cancel Subscription</button>
                        {subscriptionAmount !== newSubscriptionAmount && <button className="updateSubscription accountPageButton" onClick={UpdateSubscription}>Update Subscription</button>}
                    </div>}
                    {IsSubActive() === false && newSubscriptionAmount !== 0 && selectedPayment !== undefined && <div>
                            <button className="deactivateSubscription accountPageButton" onClick={CheckAndMakeSubscription}>Start Subscription</button>
                    </div>}
                </div>
            </div>}
        </div>
    );
}
function PaymentMethodListItem({ paymentMethod, selected, clickable, breakAfter, subscription }) {

    function SelectedMethod() {
        if(clickable === true) selected(paymentMethod);
    }

    return (
        <div className="paymentMenuDropdownItem" onClick={SelectedMethod}>
            <div className="topLineCardInfo">
                <p className="paymentTypeBrand">{paymentMethod.card.brand}</p>
                {subscription !== null && subscription !== undefined && paymentMethod.id === subscription.default_payment_method && <p className="defaultPaymentMarker">DEFAULT</p> }
            </div>
            <div className="cardInfo">
                <p className="expiration">Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
                <p className="lastDigits">Last 4:{paymentMethod.card.last4}</p>
            </div>
        </div>
    );
}

function FetchAccountInfo(setAccountInfo) {

    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetAccountInfo";
    //console.log(Cookies.get("AccessToken"));
    GetAccessToken().then((token) => {
        //console.log(Cookies.get("AccessToken"));
        //console.log(token);


        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'token': token })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => { setAccountInfo(data.message); });
    });
}

function NewDeveloperPage({ accountInfo }) {
    const [reloadPage, setReloadPage] = useState(false);
    const devId = useRef("");
    const [stateCountry, setStateCountry] = useState();
    const [stateRegion, setStateRegion] = useState();
    const [message, setMessage] = useState();

    const studioName = useRef();
    const contactEmail = useRef();
    const streetAddress1 = useRef();
    const streetAddress2 = useRef();
    const city = useRef();
    const region = useRef();
    const country = useRef();
    const zipCode = useRef();
    const EIN = useRef();

    function SubmitNewDeveloperRequest(event) {
        event.preventDefault();

        var address = {
            'streetAddress1': streetAddress1.current,
            'streetAddress2': streetAddress2.current,
            'city': city.current,
            'region': stateRegion,
            'country': stateCountry,
            'zipCode': zipCode.current
        };

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewDeveloper";

        if (studioName.current === undefined || contactEmail.current === undefined || streetAddress1.current === undefined || city.current === undefined || stateCountry === undefined || stateRegion === undefined || zipCode.current === undefined) {
            setMessage("Missing requried information to register new developer.")
            return;
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'studioName': studioName.current, 'email': contactEmail.current, 'userId': accountInfo.LudareID, 'ein': EIN.current, 'address': JSON.stringify(address) })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

                if (data.success === true) {
                    devId.current = data.message;
                    //setReloadPage(true);
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + devId.current);
                }
                else {
                    setMessage(data.message);
                }

            });

    }

    function UpdateCountry(val) {
        country.current = val;
        setStateCountry(country.current);
    }

    function UpdateState(val) {
        region.current = val;
        setStateRegion(region.current);
    }

    return (
        <div>
            {message !== undefined && <p className="newDevMessage">{message}</p>}
            <h1 className="newDevtitle">Create New Developer</h1>
            <span className="requiredHeader"> <p className="requiredLabel">Required = </p> <p className="requiredMark">{'\u2731'}</p> </span>
            <form className="newDeveloperForm">
                <div>
                    <label htmlFor="studioName">Studio Name:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="studioName" required onChange={(e) => studioName.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="studioEmail">Studio Contact Email:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="email" id="studioEmail" required onChange={(e) => contactEmail.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress1">Street Address 1:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="streetAddress1" required onChange={(e) => streetAddress1.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress2">Street Address 2:</label>
                    <input type="text" id="streetAddress2" onChange={(e) => streetAddress2.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="city">City:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="city" required onChange={(e) => city.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="country">Country:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <CountryDropdown onChange={UpdateCountry} required value={stateCountry} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'}} />
                </div>
                <div>
                    <label htmlFor="state">State:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <RegionDropdown country={stateCountry} required value={stateRegion} onChange={UpdateState} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'
                    }} />
                </div>
                <div>
                    <label htmlFor="zipCode">Zip Code:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="zipcode" required onChange={(e) => zipCode.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="ein">Tax Identification Number:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="ein" required onChange={(e) => EIN.current = e.target.value} />
                </div>
                <button className="makeNewDeveloper accountPageButton" onClick={SubmitNewDeveloperRequest}>Create</button>
                {reloadPage === true && <Navigate to={"/Account?loadPage=Developer&loadDev=" + devId.current}/>}
            </form>
        </div>
    );
}

function DeveloperPage({ devId, accountInfo, gameArray, devArray, currGame, setCurrGame }) {
    const [currPane, setCurrPane] = useState();
    const [gameDropDownVisible, setGameDropDownVisible] = useState(false);
    const [devInfo, setDevInfo] = useState();
    const [devAddress, setDevAddress] = useState();

    function ClickInfo() {
        setCurrPane("DevInfo");
    }

    useEffect(() => {

        var tmpDevInfo;

        if (devArray === undefined || devArray === null) return;

        for (var i = 0; i < devArray.length; i++) {

            if (devId !== undefined && devId.toString() === devArray[i].DeveloperID.toString()) {
                tmpDevInfo = devArray[i];
                break;
            }
        }

        if (tmpDevInfo !== undefined) {
            var addr = JSON.parse(tmpDevInfo.Address);
            setDevAddress(addr);
        }

        setDevInfo(tmpDevInfo)
    }, [devArray, devId]);

    useEffect(() => {

        for (var i = 0; i < devArray.length; i++) {
            if (devId === devArray[i].DeveloperID) {
                setDevInfo(devArray[i]);
                setDevAddress(JSON.parse(devArray[i].Address));
                break;
            }
        }

    }, [devId]);

    return (
        <div className="devPagePane">
            {devInfo !== undefined && <h1 className="developerTitle">{devInfo.DevName}</h1>}
            {devInfo !== undefined && devInfo.Status === 1 && <div>
                <p className="devPending">Developer approval pending.</p>
            </div>}
            {devInfo !== undefined && devInfo.Status === 2 && <div>
                <p className="devDenied">Developer request needs changes.</p>
            </div>}
            {devInfo !== undefined && devInfo.Status === 3 && <div>
                <p className="devDenied">Developer request denied.</p>
            </div>}
            {devInfo !== undefined && devInfo.Status === 4 && <div>
                <div className="devPageHeader">
                    <div className="gameHoverDiv" onMouseEnter={(e) => { setGameDropDownVisible(true) }} onMouseLeave={(e) => { setGameDropDownVisible(false) }}>
                        <button className="gameDropDownButton" style={{ 'background-color': (currPane !== "DevInfo") ? "#ffcf00" : "lightgray" }}><GameTableEntry gameInfo={currGame} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /></button>
                        <div className="gameDropDownDiv">
                            {gameDropDownVisible === true && <div className="gameDropDownList">
                                {gameArray !== undefined && gameArray.length > 0 && gameArray.map((gameInfo, index) => {
                                    return <div className="gameTableEntryParent"><GameTableEntry gameInfo={gameInfo} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /><hr /></div>
                                })}
                                <GameTableEntry setCurrGame={setCurrGame} setCurrPane={setCurrPane} />
                            </div>}
                        </div>
                    </div>
                    <button className="developerInfoButton" onClick={ClickInfo} style={{ 'background-color': (currPane === "DevInfo") ? "#ffcf00" : "lightgray" }}>Developer Info</button>
                </div>
                {currPane === "DevInfo" && devInfo !== undefined &&
                    <div className="devInfoPane">

                        <div>
                            <p>Address:</p>
                            <p>{devAddress.streetAddress1 + " " + devAddress.streetAddress2 + " " + devAddress.city + " " + devAddress.state + ", " + devAddress.country + " " + devAddress.zipCode}</p>
                        </div>
                        <div>
                            <p>Email:</p>
                            <p>{devInfo.ContactEmail}</p>
                        </div>
                    </div>
                }
                {(currPane === undefined || currPane === "GameInfo") &&
                    <div>
                        {currGame !== undefined &&
                            <GamePage gameId={currGame} />
                        }
                        {currGame === undefined &&
                            <NewGamePage developerId={devId} />
                        }
                    </div>
                }
            </div>}
        </div>
    );
}

function GameTableEntry({ gameInfo, setCurrGame, setCurrPane }) {

    function GameClick() {
        setCurrGame(gameInfo);
        setCurrPane("GameInfo");
    }

    return (
        <div className="gameTableEntry">
            <p onClick={GameClick}>{(gameInfo === undefined || gameInfo === null) ? "Make New Game" : gameInfo}</p>
        </div>
    );
}

function GamePage({ gameId }) {
    const [gameInfo, setGameInfo] = useState();
    const [gameData, setGameData] = useState();
    const [upload, setUpload] = useState(true);
    const [buildUploadProgress, setBuildUploadProgress] = useState(null);

    useEffect(() => {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameInfo";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameInfo(data.message);
                }
            });

        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameEvents";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameData(data.message);
                }
            });

    }, [gameId]);

    function UploadImage(gameId, type, fileContents) {
        setUpload(false);
        const name = `games/${gameId}/${type}.png`

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        })

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: fileContents,
        }

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log("Updating " + parseInt((evt.loaded * 100) / evt.total) + "%");
            }).promise().then((err, data) => {
                console.log("File Uploaded");
                setUpload(true);
            });
        
    }

    function BuildUpload() {
        setUpload(false);
        var fileName = document.getElementById('uploadBuild').files[0].name;

        const name = `games/${gameInfo.ID}/${fileName}`;

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        })

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: document.getElementById('uploadBuild').files[0],
        }

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                setBuildUploadProgress({ 'loaded': evt.loaded, 'total': evt.total });
                console.log("Updating " + parseInt((evt.loaded * 100) / evt.total) + "%");
            }).promise().then((err, data) => {
                if (err) {
                    console.log(err);
                }
                
                console.log("File Uploaded");
                setUpload(true);

                UpdateBuildLinks(name);
            });

    }

    function UpdateBuildLinks(newLink) {
        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GameBuildUploaded";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId, 'buildLink': newLink })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    var tempGameInfo = gameInfo;
                    tempGameInfo.PublicBuildLinks = JSON.parse(data.message);
                    setGameInfo(tempGameInfo);
                }
            });
    }

    function CopySecret(event) {
        event.preventDefault();

        navigator.clipboard.writeText(gameInfo.Secret);

        alert("Secret Copied");
    }

    function handleUpload() {
        const gameId = gameInfo.ID;
        const background = document.getElementById('uploadBackground').files[0];
        const keyArt = document.getElementById('uploadKeyArt').files[0];

        if (!background || !keyArt) {
            console.log("Missing background/keyArt");
            return;
        }

        console.log('Background:', background);
        console.log('KeyArt:', keyArt);

        UploadImage(gameId, "background", background);
        UploadImage(gameId, "keyArt", keyArt);
        UploadPictureLink(gameId);
    }

    function updateWindowsBuild(event) {
        event.preventDefault();
        gameInfo.PublicBuildLinks.Windows = event.target.value;
    }

    function updateMacBuild(event) {
        event.preventDefault();
        gameInfo.PublicBuildLinks.Windows = event.target.value;
    }

    function updateLinuxBuild(event) {
        event.preventDefault();
        gameInfo.PublicBuildLinks.Windows = event.target.value;
    }

    return (
        <div className="gameInfoPane">
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 4 &&
                <form className="gameInfoForm">
                    <div>
                        <label htmlFor="gameName">Game Name HERE:</label><input id="gameName" readOnly value={gameInfo.Name} />
                    </div> <br />
                    <div>
                        <label htmlFor="gameId">Game ID:</label> <input id="gameId" readOnly value={gameInfo.ID} />
                    </div> <br />
                    <div>
                        <label htmlFor="gameSecret">Game Secret:</label> <input id="gameSecret" readOnly value="*************************************************" /><button className="copySecretButton accountPageButton" onClick={CopySecret} >Copy Secret</button>
                    </div> <br />

                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div>
                            <label htmlFor="uploadBackground">Upload Background Here:</label>
                            <input type="file" id="uploadBackground" onChange={handleUpload}/>
                        </div>

                        <div>
                            <label htmlFor="uploadKeyArt">Upload Key Art Here:</label>
                            <input type="file" id="uploadKeyArt" onChange={handleUpload}/>
                        </div>

                        <div>
                            <label htmlFor="uploadBuild">Upload Game Build Here:</label>
                            <input type="file" id="uploadBuild" onChange={BuildUpload} />
                            {buildUploadProgress !== null &&
                                <span><br /> <progress id="build" value={(buildUploadProgress.loaded/buildUploadProgress.total)*100} max="100"/></span>
                            }
                        </div> 
                        <div>
                            <div className="buildSelector">
                                <p className="windowsBuildLabel">Windows Build:</p>
                                <select value="" onChange={updateWindowsBuild} >
                                    <option key="" value="" />
                                    {gameInfo.PublicBuildLinks !== null && gameInfo.PublicBuildLinks !== undefined && gameInfo.PublicBuildLinks.Links !== null && gameInfo.PublicBuildLinks.Links !== undefined && gameInfo.PublicBuildLinks.Links.map((link, index) => {
                                        var parts = link.split('/');
                                        return <option key={index} value={parts[parts.length - 1]} />
                                    })}
                                </select>
                            </div>

                            <div className="buildSelector">
                                <p className="macBuildLabel">Mac Build:</p>
                                <select value="" onChange={updateWindowsBuild} >
                                    <option key="" value=""/>
                                    {gameInfo.PublicBuildLinks !== null && gameInfo.PublicBuildLinks !== undefined && gameInfo.PublicBuildLinks.Links !== null && gameInfo.PublicBuildLinks.Links !== undefined && gameInfo.PublicBuildLinks.Links.map((link, index) => {
                                        var parts = link.split('/');
                                        return <option key={index} value={parts[parts.length - 1]} />
                                    })}
                                </select>
                            </div>

                            <div className="buildSelector">
                                <p className="linuxBuildLabel">Linux Build:</p>
                                <select value="" onChange={updateWindowsBuild} >
                                    <option key="" value="" />
                                    {gameInfo.PublicBuildLinks !== null && gameInfo.PublicBuildLinks !== undefined && gameInfo.PublicBuildLinks.Links !== null && gameInfo.PublicBuildLinks.Links !== undefined && gameInfo.PublicBuildLinks.Links.map((link, index) => {
                                        var parts = link.split('/');
                                        return <option key={index} value={parts[parts.length - 1]} />
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    {gameData !== undefined && gameData.length > 0 && <div> 
                        <h3 className="gameEventHeader">Recent Events</h3>
                        <span className="gameEventEntry"> <p>UserId</p> <p>Timestamp</p> <p>Event</p> </span>
                        {gameData.map((event, index) => {
                            return <span className="gameEventEntry"> <p>{event.UserID}</p> <p>{event.Timestamp.slice(0, 19).replace('T', ' ')}</p> <p>{event.Event}</p> </span>
                        }) }
                    </div>}
                </form>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 1 &&
                <div>
                    <p className="gameUnderReview">Your game is under review.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 2 &&
                <div>
                    <p className="gameChangesNeeded">Please edit your game to be approved.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 3 &&
                <div>
                    <p className="gameDenied">Your game application is deined.</p>
                </div>
            }
        </div>
    );
}

function NewGamePage({developerId }) {
    const gameName = useRef();
    const [message, setMessage] = useState();
    const description = useRef();
    const buildLink = useRef();
    const imageLink = useRef();

    function SubmitNewGame(event) {
        event.preventDefault();

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewGame";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameName': gameName.current, 'developerId': developerId, 'description': description, 'imageLink': imageLink, 'buildLink': buildLink})
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + developerId + "&loadGame=" + data.message);
                }
                else {
                    setMessage(data.message);
                }
            });
    }

    return (
        <div className="newGameDiv">
            {message !== undefined && <p className="newGameMessage">{message}</p>}
            <form className="newGameForm">
                <label htmlFor="gameName">Game Name: </label>
                <input type="text" id="gameName" onChange={(e) => { gameName.current = e.target.value }} /> <br />
                <label htmlFor="description">Description: </label>
                <input type="text" id="description" onChange={(e) => { description.current = e.target.value }} /> <br />
                <label htmlFor="buildLink">Build Link: </label>
                <input type="text" id="buildLink" onChange={(e) => { buildLink.current = e.target.value }} /> <br />
                <label htmlFor="imageLink">Image Link: </label>
                <input type="text" id="imageLink" onChange={(e) => { imageLink.current = e.target.value }} /> <br />
                <button className="newGameSubmit accountPageButton" onClick={SubmitNewGame}>Make New Game</button>
            </form>
        </div>
    );
}

function DeveloperListItem({ developerInfo, setDeveloperId, setPage, setDevId, setCurrGame, currDevId, setCurrDevId, currPage }) {
    const [developerName, setDeveloperName] = useState("");

    useEffect(() => {

        if (developerInfo === null || developerInfo === undefined) {
            setDeveloperName("+ Create New Developer");
        }
        else {
            setDeveloperName(developerInfo.DevName);
        }
    }, [])

    function SetDeveloperPage() {

        setCurrGame(undefined);

        if (developerInfo === null || developerInfo === undefined) {
            setPage("NewDeveloper");
            setCurrDevId(undefined);
        }
        else {
            setDevId(developerInfo.DeveloperID);

            setPage("Developer");
        }
    }

    return (
        <div>
            {developerName !== undefined && <button style={{ background: (((developerInfo !== undefined && currDevId === developerInfo.DeveloperID) || (developerInfo === undefined && currPage === "NewDeveloper")) ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }} className="developerMenuButton" onClick={SetDeveloperPage}>{developerName}</button>}
        </div>
    );
}
