// JavaScript source code
import './styles/LoginPage.css';
import { Navigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { SetTokens, CheckUsername } from './Utilities';
import { AccountConfirmation } from "./Emails/ConfirmationEmail";
import { ParentalApprovalEmail } from "./Emails/ParentalApprovalEmail";
import { render } from '@react-email/render';
import { PasswordCheck } from './Utilities';
import ReactGA from 'react-ga4';
import RedditPixel from 'react-reddit-pixel';

ReactGA.initialize('G-P1CP4P4GZ6');

function LoginPage({ status, setStatus }) {
    //const initiateAuth

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [challenge, setChallenge] = React.useState("");
    const [logIn, setLogIn] = React.useState(true);
    const [confirmSignUp, setConfirmSignUp] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [mfaCode, setMFACode] = React.useState("");
    const [passwordLengthCheck, setPasswordLengthCheck] = React.useState(false);
    const [specialCharCheck, setSpecialCharCheck] = React.useState(false);
    const [capitalLetterCheck, setCapitalLetterCheck] = React.useState(false);
    const [lowerLetterCheck, setLowerLetterCheck] = React.useState(false);
    const [numberCheck, setNumberCheck] = React.useState(false);
    const [tosAgree, setTosAgree] = React.useState(false);
    const [usernameCheck, setUsernameCheck] = React.useState();
    const [usernameCheckRes, setUsernameCheckRes] = React.useState("");
    const [dateOfBirth, setDateOfBirth] = React.useState(new Date());
    const [parentalEmail, setParentalEmail] = React.useState("");
    const [isMinor, setIsMinor] = React.useState(false);
    const [marketingAgreement, setMarketingAgreement] = React.useState(false);
    const [queryParams] = useSearchParams();

    var testPassword;

    useEffect(() => {
        var diff = new Date();
        diff.setTime(diff.getTime() - dateOfBirth.getTime());
        setIsMinor(diff.getFullYear() - 1970 < 18);
    }, [dateOfBirth]);

    useEffect(() => {
        if (queryParams.get("page") === "login") {
            setLogIn(true);
        }
        else if (queryParams.get("page") === "signUp") {
            setLogIn(false);
        }
    }, [queryParams]);

    useEffect(() => {
        if (confirmSignUp === true) {
            ReactGA.event({
                category: "User",
                action: "Sign Up",
            })
        }
    }, [confirmSignUp])

    const login = (event) => {
        if(event !== undefined) event.preventDefault();

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': username, 'password': password })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/Login', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === false) {
                    if (data.reason === 1) {
                        setMessage(data.message);
                        setPassword("");
                        return;
                    }
                    setMessage("Login failed. Verify username and password are correct");
                    setPassword("");
                    return;
                }
                setChallenge(data.ChallengeName);

                if (challenge === undefined || challenge === "") {
                    if (data.message.accessToken !== undefined && data.message.accessToken !== "") {
                        SetTokens(data);
                        setStatus(true);
                    }
                }
                else if (challenge === "NEW_PASSWORD_REQUIRED") {
                    if (data.message.Session !== "") {
                        Cookies.set('SessionToken', data.message.Session, { expires: 7 });
                    }
                }
                else if (challenge === "SMS_MFA") {
                    if (data.message.Session !== "") {
                        Cookies.set('SessionToken', data.message.Session, { expires: 7 });
                    }
                }
            });
    };

    const passwordChallenge = (event) => {
        event.preventDefault();
        if (newPassword === confirmPassword) {
            const sessionToken = Cookies.get('SessionToken');
            if (sessionToken === undefined || sessionToken === "") {
                fetch("https://www.devpowered.com");
                return;
            }

            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'token': sessionToken, 'username': username, 'newPassword': newPassword })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/PasswordChallenge', reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    SetTokens(data);
                });
        }
    };

    const signUp = (event) => {
        event.preventDefault();

        if (tosAgree !== true) {
            setMessage("You need to accept the Terms of Service.");
            return;
        }

        if (isMinor === true && ( parentalEmail === undefined || parentalEmail === null) ) {
            setMessage("You need to provide a valid parental email.");
            return;
        }

        if (lowerLetterCheck === false || capitalLetterCheck === false || specialCharCheck === false || numberCheck === false || passwordLengthCheck === false) {
            setMessage("Password doesn't meet requirements.");
            return;
        }

        var dob = new Date(dateOfBirth);
        var today = new Date();

        if (today.getFullYear() - dob.getFullYear() < 13) {
            setMessage("You are too young for an account.");
            return;
        }

        if (username !== undefined && username !== "" && password !== undefined && password !== "" && email !== undefined && email !== "") {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'password': password, 'username': username, 'email': email, 'birthday': dateOfBirth, 'parentalEmail': parentalEmail, 'parentalMessage': render(ParentalApprovalEmail(username)), 'marketingAgreement': marketingAgreement })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/SignUp', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success !== true) {
                        setMessage("Failed to create account");
                    }
                    else {
                        const signUpReqOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 'email': email, 'message': render(AccountConfirmation(username)), username: username })
                        };

                        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SendConfirmationEmail', signUpReqOptions)
                            .then((res) => res.json())
                            .then((data) => {
                                if (data.success === true) {
                                    var callback = function () {
                                        setConfirmSignUp(true);
                                    }

                                    RedditPixel.init('a2_f8s8vf4d4enx');
                                    RedditPixel.track("SignUp");

                                    ReactGA.gtag('event', 'conversion_event_signup', {
                                        'event_callback': callback,
                                        'event_timeout': 2000,
                                    });
                                }
                            });
                    }
                });
        }

    };

    const selectLogIn = (event) => {
        event.preventDefault();
        setLogIn(true)
        setMessage("");
    };

    const selectSignUp = (event) => {
        event.preventDefault();
        setLogIn(false)
        setMessage("");
    };

    function CheckCurrentUsername() {
        CheckUsername(username, setUsernameCheck, setUsernameCheckRes);
    }

    return (
        <div className="loginMainPage">
            <div className="loginTitleContainer">
                <h1 className="loginText1">Welcome to</h1>
                <h1 className="loginText2">Ludare</h1>
            </div>
            <div className="formContents">
                <div className="optionBar">
                    <button className="logIn" onClick={selectLogIn} style={{ color: (logIn === true) ? "#ffcf00" : "#FFFFFF" }}>Log In</button>
                    <button className="signUp" onClick={selectSignUp} style={{ color: (logIn === false) ? "#ffcf00" : "#FFFFFF" }}>Sign Up</button>
                </div>
                {message !== undefined && <p className="infoMessage">{message}</p>}
                {challenge === "" && logIn === false && < div className="signUpPage" >
                    <span className="requiredHeader"> <p className="signUpRequiredLabel">All field marked with a ({'\u2731'}) are required.</p> </span>
                    <form className="signUpForm" onSubmit={signUp}>
                        <label htmlFor="username">Username{'\u2731'}</label><br />
                        <input className="signUpTextInput" type="text" id="username" name="username" required onChange={(e) => setUsername(e.target.value)} /><br />
                        <div className="confirmUsername"><button class="checkName" onClick={CheckCurrentUsername}>Check DisplayName</button> {usernameCheck !== undefined && <p className="usernameCheckRes" style={{ color: (usernameCheck === true) ? "lightgreen" : "red" }}>{usernameCheckRes}</p>} </div>
                        <label htmlFor="email">Email{'\u2731'}</label><br />
                        <input className="signUpTextInput" type="email" id="email" name="email" required onChange={(e) => setEmail(e.target.value)} /><br />
                        <label htmlFor="password">Password{'\u2731'}</label><br />
                        <input className="signUpTextInput" type="password" id="password" name="password" required onChange={(e) => { setPassword(e.target.value); testPassword = e.target.value; PasswordCheck( testPassword, setLowerLetterCheck, setCapitalLetterCheck, setSpecialCharCheck, setNumberCheck, setPasswordLengthCheck); }} /><br />
                        <div className="passwordRequirements">
                            <span style={{ color: (passwordLengthCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{passwordLengthCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must be at least 8 characters</p></span>
                            <span style={{ color: (lowerLetterCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{lowerLetterCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a lowercase letter</p></span>
                            <span style={{ color: (capitalLetterCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{capitalLetterCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a capital letter</p></span>
                            <span style={{ color: (specialCharCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{specialCharCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a special character</p></span>
                            <span style={{ color: (numberCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{numberCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a number</p></span>
                        </div>
                        <label htmlFor="confirmPassword">ConfirmPassword{'\u2731'}</label><br />
                        <input className="signUpTextInput" type="password" id="confirmPassword" name="confirmPassword" required onChange={(e) => setConfirmPassword(e.target.value)} /><br />
                        {confirmPassword !== password && <p className="passMatch">Passwords Must Match</p>}
                        <label htmlFor="DOB">Date of Birth{'\u2731'}</label><br />
                        <input className="DOBPicker" type="date" id="DOB" name="DOB" required onChange={(e) => setDateOfBirth(new Date(e.target.value))} defaultValue={dateOfBirth} /><br />
                        {isMinor === true && <div> <label htmlFor="parentalEmail">Parental Email{'\u2731'}</label><br />
                            <input className="signUpTextInput" type="email" id="parentalEmail" name="parentalEmail" onChange={(e) => setParentalEmail(e.target.value)} /><br /> </div>}
                        <input type="checkbox" className="agreeToTOS" id="TOS" name="TOS" value="agree" onClick={(e) => {setTosAgree(e.target.checked);}} />
                        <label for="TOS" required className="TOSLabel">I agree to the <a href="/TOS" target="_blank" className="TOSLink">Ludare Terms of Service.</a>{'\u2731'}</label><br />
                        <input type="checkbox" className="marketingAgreement" id="marketing" name="marketing" value="agree" onClick={(e) => { setMarketingAgreement(e.target.checked); }} />
                        <label for="marketing" required className="marketingLabel">I am interested in recieving special deals and news from Ludare.</label><br />
                        <input className="signUpSubmit" name="submit" type="submit" value="Submit" />
                    </form>
                </div >}
                {challenge === "" && logIn === true && < div className="logInPage" >
                    <form className="logInForm" onSubmit={login}>
                        <label htmlFor="username">Username:</label><br />
                        <input className="logInTextInput" type="text" id="username" name="username" value={username} required onChange={(e) => setUsername(e.target.value)} /><br />
                        <label htmlFor="password">Password:</label><br />
                        <input className="logInTextInput" type="password" id="password" name="password" value={password} autoComplete="current-password" required onChange={(e) => setPassword(e.target.value)} /><br />
                        <div className="forgotCredentials">
                        <a className="forgotPassword" href="/ForgotPassword">Forgot Password</a>
                        </div>
                        <input className="logInSubmit" name="submit" type="submit" value="Log In" />
                    </form>
                </div >}
            </div>
            {status && <Navigate to='/Account' />}
            {confirmSignUp && <Navigate to='/confirmation' />}
        </div>
    );
}

export default LoginPage;
