import React, { useState, useEffect } from 'react';
import { GetGame, GetDeveloperInfo, GetTagInfo } from "../../Utilities";
import Popup from './Popup.js'
import '../../styles/Game.css';

const GameCard = ({ gameId }) => {
  const [gameData, setGameData] = useState(null);
  const [developerInfo, setDeveloperInfo] = useState(null)
  const [error, setError] = useState(null);
  // NEW HERE FOR POPUP
  const [showPopup, setShowPopup] = useState(false);
  const [tagData, setTagData] = useState([]);


  useEffect(() => {
    const fetchGameAndDeveloper = async () => {
      try {
        const game = await GetGame(gameId);
        if (!game) {
          throw new Error('Game not found');
        }
        setGameData(game.message);

        const developer = await GetDeveloperInfo(game.message.DeveloperID);
        if (!developer) {
          throw new Error('Developer not found');
        }
        setDeveloperInfo(developer.message);

        console.log("WOWWWWW", game.message.TagId);
        const tagNames = await GetTagInfo(game.message.TagId);

        if (!tagNames) {
          throw new Error('Game tag not found');
        }

        setTagData(tagNames);



      } catch (err) {
        setError(err.message);
      }
    };

    fetchGameAndDeveloper();
  }, [gameId]);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!gameData || !developerInfo) {
    return <div>Loading...</div>;
  }

  const { Name, TagId = [], Description, PhotoUrl, SteamLink, EpicLink } = gameData;
  const { DevName } = developerInfo;

  const fullPhotoLink = `${process.env.REACT_APP_S3_BASE_URL}${PhotoUrl}`
  const maxLength = 70;
  const truncatedDescription =
    Description.length > maxLength
      ? Description.substring(0, maxLength - 10) + "... " //can update this logic to cut to the last word instead of characters
      : Description;

  const seeMore = Description.length > maxLength ? (
    <span className="see-more">See More</span>
  ) : null;


  const handleClick = () => {
    setShowPopup(!showPopup);
  };



  return (
    <div>
        {
          showPopup && (
            <Popup handleClick={handleClick} gameData={gameData} tagData={tagData} developerInfo={developerInfo}/>
          )
        }
      <div className="game-card" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <div className="game-card-image">
          <img src={fullPhotoLink} alt="Game cover" />
        </div>
        <div className="game-card-title">
          <text>{Name}</text>
        </div>
        <div className='game-card-content'>
          <div className="game-card-studio">Studio:</div>
          <div className="game-card-studio-name">{DevName}</div>
          <div className="game-card-tags">
            {tagData.map((tag, index) => (
              <span key={index} className="game-card-tag">{tag}</span>
            ))}
          </div>
          <p className="game-card-description">
            {truncatedDescription}{seeMore && <>{seeMore}</>}
          </p>
          <div className="game-card-platforms">
            {SteamLink && (
              <a href={SteamLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.PUBLIC_URL} steamIcon.png`}
                  alt="Steam"
                  className="platform-logo"
                />
              </a>
            )}
            {EpicLink && (
              <a href={EpicLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={EpicLink}
                  alt="Epic Games"
                  className="platform-logo"
                />
              </a>
            )}
          </div>

        </div>

      </div>
    </div>
  );
};

export default GameCard;

// export function FullGameCard({ info, closePopup }) {
//     const [title, setTitle] = useState();
//     const [popupImages, setPopupImages] = useState([]);
//     const [studio, setStudio] = useState();
//     const [tags, setTags] = useState(['Simulation', 'Otome', 'LGBTQ+', 'Romance']);
//     const [Description, setDescription] = useState('An otome game inspired by witchy sitcoms');
//     const [platformLinks, setPlatformLinks] = useState({});

//     useEffect(() => {
//         setTitle(info.gameTitle);
//         setPopupImages(info.popupImages);
//         setStudio(info.studioName);
//         setTags(info.tags);
//         setDescription(info.Description);
//         setPlatformLinks(info.platformLinks);
//     }, [info]);

//     return (
//         <div className="fullCardContainer">
//             <div className="fullCardContent">
//                 {popupImages !== undefined && popupImages.length > 0 && <img className="fullCardImage" src={popupImages[0]} alt="Game Image" />}
//                 <div className="fullCardText">
//                     <p className="game-card-title">{title}</p>
//                     <div className="game-card-studio">
//                         <p>Studio: {studio}</p>
//                     </div>
//                     <div className="game-card-tags">
//                         {tags.map((tag, index) => (
//                             <span key={index} className="game-card-tag">{tag}</span>
//                         ))}
//                     </div>
//                     <p className="fullGameDescription">
//                         {Description}
//                     </p>
//                     <div className="game-card-platforms">
//                         <a href={platformLinks.SteamLink !== undefined && platformLinks.SteamLink}><img src="steamIcon.png" /></a>
//                     </div>
//                 </div>
//             </div>
//             <div className="close-btn" onClick={closePopup}>x</div>
//         </div>
//     );
// }

// export class GameCardInfo {
//     constructor(imageSource, tags, gameTitle, studioName, platformLinks, description, popupImages) {
//         this.imageSource = imageSource;
//         this.tags = tags;
//         this.gameTitle = gameTitle;
//         this.studioName = studioName;
//         this.platformLinks = platformLinks;
//         this.description = description;
//         this.popupImages = popupImages;
//     }
// }

