// JavaScript source code
import { useSearchParams, Navigate } from "react-router-dom";
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { GetAccessToken } from "./Utilities";

function GoogleLinkReturn() {
    const [accountLinked, setAccountLinked] = useState(false);
    const [queryParams] = useSearchParams();

    useEffect(() => {

        if (queryParams.get("code") !== null) {
            const idReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'code': queryParams.get("code"), 'client_id': "289770615480-dk7l2cnsr9jqecgtidmtbfor5gcalagh.apps.googleusercontent.com", 'client_secret': "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", 'redirect_uri': process.env.REACT_APP_BASE_ADDRESS + "/GoogleReturn", 'grant_type': "authorization_code"
                })
            };

            fetch("https://oauth2.googleapis.com/token", idReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    const decode = jwtDecode(data.id_token);

                    GetAccessToken().then((token) => {
                        const linkedIdReqOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 'token': token, 'ID': decode.sub, 'type': "google" })
                        };

                        fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", linkedIdReqOptions)
                            .then((res) => res.json())
                            .then((data) => {
                                setAccountLinked(data.success);
                                console.log(data.success);
                            });
                    });
                });
        }

    }, []);

    return (
        <div>
            {accountLinked && <Navigate to='/account' />}
        </div>
        );
}

export default GoogleLinkReturn;