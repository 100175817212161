// JavaScript source code
import './styles/StudiosPage.css';
import GameImageHeader from './GameImageHeader';
import { useState, useRef } from 'react';

function StudiosPage() {

    return (
        <div className="studioPage">
            <div className="titleLineStudio">
                <GameImageHeader />
                <div className="topBodyStudio">
                    <div className="infoStudio">
                        <h2 className="taglineTopStudio">Make money on</h2>
                        <h2 className="taglineBottomStudio">every minute played</h2>
                        <p className="subTagLineStudio">As a developer all you need to do is register for the <br/> service and integrate our plugin with your game.</p>
                    </div>
                </div>
            </div>
            <div className="studioInfoContainer">
                <h1 className="studiosSectionHeader">Start Earning in 3 Easy Steps</h1>
                <div className="applyStep">
                    <div className="applyTile">
                        <img className="applyIcon" src="web-browser-recolored.png" />
                        <p className="applyTileInfo">To become recognized as a developer with Ludare, set up an account and submit a developer request.
                            <br/> <br/>
                            Ludare reviews applications promptly and will inform you of your status soon. Once recognized, you will receive all necessary resources to get started.</p>
                    </div>
                    <div className="applyMap">
                        <h1 className="applyStepNum">01.</h1>
                        <p className="applyStepTitle">APPLY FOR DEVELOPER ACCESS</p>
                        <img className="applyMapImage" src="MapLine1.png" />
                    </div>
                </div>
                <div className="pluginStep">
                    <div className="pluginMap">
                        <h1 className="pluginStepNum">02.</h1>
                        <p className="pluginStepTitle">INTEGRATE THE PLUGIN</p>
                        <img className="pluginMapImage" src="MapLine2.png" />
                    </div>
                    <div className="pluginTile">
                        <img className="pluginIcon" src="plug-recolored.png" />
                        <p className="pluginTileInfo">After being accepted into the developer program, download the plugin for your engine or language.
                            <br/> <br/>
                            The plugins are mostly self-maintaining, but you'll need to ensure they run properly during gameplay and pass the correct identifiers to track the game being played.</p>
                    </div>
                </div>
                <div className="updateStep">
                    <div className="updateTile">
                        <img className="updateIcon" src="cloud-upload-recolored.png" />
                        <p className="updateTileInfo">After successful integration and testing, release an update for your game. Players will then be able to register their play. You can monitor player activity and earnings through your developer dashboard.</p>
                    </div>
                    <div className="updateMap">
                        <h1 className="updateStepNum">03.</h1>
                        <p className="updateStepTitle">UPDATE YOUR GAME</p>
                    </div>
                </div>
            </div>
            <div className="supportedEnginesContainer">
                <h1 className="supportedEnginesTitle">SUPPORTED ENGINES</h1>
                <div className="unityContainer">
                    <img className="unityLogo" src="U_Logo_White_RGB.png" />
                </div>
                <div className="unrealContainer">
                    <img className="unrealLogo" src="UE_Logo_icon-only_white.png" />
                    <p className="unrealTitle">Unreal</p>
                </div>
                <div className="godotContainer">
                    <img className="godotLogo" src="GodotLogo.png" />
                </div>
            </div>
        </div>
    );
}

export default StudiosPage;
