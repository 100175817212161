// JavaScript source code
import { useRef, useState } from 'react';
import GameImageHeader from './GameImageHeader';
import './styles/PlayerInfo.css';

function PlayerInfo() {

    return (
        <div className="playerPage">
            <div className="titleLinePlayer">
                <GameImageHeader />
                <div className="topBodyPlayer">
                    <div className="infoPlayer">
                        <h2 className="taglineTopPlayer">Dare to play</h2>
                        <h2 className="taglineBottomPlayer">indie games</h2>
                        <p className="subTagLinePlayer">Ludare is a game subscriptions service meant to support <br/> indie developers based on the way you play their games.</p>
                    </div>
                </div>
            </div>
            <div className="playerInfoContainer">
                <h1 className="playerSectionHeader">Start Playing in 3 Easy Steps</h1>
                <div className="signUpStep">
                    <div className="signUpTile">
                        <img className="signUpIcon" src="pc-recolored.png" />
                        <p className="signUpTileInfo">To support your favorite indie game, sign up on our website and link your accounts from other platforms. This allows us to track your play data and ensure developers get paid. We're adding new platform connections regularly, so if your favorite game isn't available yet, it should be soon.</p>
                    </div>
                    <div className="signUpMap">
                        <h1 className="signupStepNum">01.</h1>
                        <p className="signUpStepTitle">SIGN UP</p>
                        <img className="signUpMapImage" src="MapLine1.png" />
                    </div>
                </div>
                <div className="paymentStep">
                    <div className="paymentMap">
                        <h1 className="paymentStepNum">02.</h1>
                        <p className="paymentStepTitle">SET UP YOUR PAYMENT</p>
                        <img className="paymentMapImage" src="MapLine2.png" />
                    </div>
                    <div className="paymentTile">
                        <img className="paymentIcon" src="banknote-recolored.png" />
                        <p className="paymentTileInfo">Next, set up recurring payments via Stripe so we can smoothly transfer your monthly payments to your favorite indie developers. We only deduct necessary payment processing fees and taxes. Currently, there are no additional platform fees, and we aim to cover operational costs only as the platform grows.</p>
                    </div>
                </div>
                <div className="playStep">
                    <div className="playTile">
                        <img className="playIcon" src="gamepad-recolored.png" />
                        <p className="playTileInfo">Launch your favorite game that supports our service, indicated by our logo on the menu page. Click on our icon to sign in and ensure we know who to pay.</p>
                    </div>
                    <div className="playMap">
                        <h1 className="playStepNum">03.</h1>
                        <p className="playStepTitle">BOOT UP YOUR FAVORITE GAME</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayerInfo;

/*<li className="playerTitle">
                <h1>Players</h1>
                <h3>Dare to play new games!</h3>
            </li>
            <li className="productDescription">
                <div>
                    <h3>What is Ludare?</h3>
                    <p>Ludare is a game subscriptions service meant to support indie developers based on the way you play their games. This support is driven in a 3 step process:</p>
                </div>
                <img src="LudareOrangeLinesNoBorderNoBottomText.png" alt="Placeholder"/>
            </li>
            <hr/>
            <li className="signUpText">
                <img src="keyboard.svg" alt="Placeholder" />
                <div>
                    <h3>Step 1: Sign Up</h3>
                    <p>The first step to supporting your favorite indie is to sign up on our website and connect your accounts on other platforms. This way, we will be able to register your play data with your account to know who is playing and how to pay developers. We are continuing to roll out new platform connections as we build out this platform. So, if your favorite game isn't on there now, we hope to have it one shortly.</p>
                </div>
            </li>
            <li className="setupPayment">
                <div>
                    <h3>Step 2: Setup Your Recurring Payment</h3>
                    <p>The next step is to setup your recurring payments through our payment provider, Stripe. This way, we can withdraw your monthly payment and ensure that it gets to your favorite indies in a smooth process. Currently, the only fee we take out of this recurring payment is the mandatory fees our payment processer requires and taxes. We do not plan to charge a platform free as the service is getting started and will only look to meet our operational requirements once the platform has grown.</p>
                </div>
                <img src="banknote.svg" alt="Placeholder" />
            </li>
            <li className="playGames">
                <img src="gamepad.svg" alt="Placeholder" />
                <div>
                    <h3>Step 3: Boot up a Ludare enabled game</h3>
                    <p>Boot up your favorite game that supports our service. You can tell which games support our platform because our logo will be included on the menu page. Clicking on our icon ensures you are signed in as playing and that we know who to pay out to.</p>
                </div>
            </li>*/
