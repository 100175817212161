// JavaScript source code
import "./styles/CookieFooter.css";
import { CookiesStatus } from "./Utilities";
import Cookies from "js-cookie";
import { useState } from 'react';

function CookieFooter() {
    const [animState, setAnimState] = useState(Cookies.get("LudareCookiePolicy") === undefined ? "slideUp" : "");

    function AcceptAllCookies() {
        Cookies.set("LudareCookiePolicy", CookiesStatus.allCookies);
        setAnimState("slideDown");
    }

    function RejectCookies() {
        Cookies.set("LudareCookiePolicy", CookiesStatus.rejectCookies);
        setAnimState("slideDown");
    }

    return (
        <div className={"footerContainer " + animState}>
            <div className="footerText">
                <p className="cookieText">Cookies help make our service better through technologies such as identifying what is most interesting for our users and improving how we serve that content. By clicking "Accept All", you constent to our use of cookies. <a href="/CokkiePolicy">Cookie Policy</a> </p>
            </div>
            <div className="footerButtons">
                <button className="acceptAll" onClick={AcceptAllCookies}>Accept All</button>
                <button className="acceptOnlyNeccesary" onClick={RejectCookies}>Reject Cookies</button>
            </div>
        </div>
    );
}

export default CookieFooter;
