// Popup.js
import React from 'react';
import '../../styles/Popup.css';
import { GetGame } from "../../Utilities";

const Popup = ({ handleClick, gameData, tagData,  developerInfo}) => {
    const REACT_APP_S3_BASE_URL="https://ludaregamedatastorage.s3.us-east-2.amazonaws.com/";

    var { Name, Description, BackgroundUrl, SteamLink, EpicLink } = gameData;
    var { DevName } = developerInfo;

    const fullPhotoUrl = `${REACT_APP_S3_BASE_URL}${BackgroundUrl}`
    return (
        <div className="popup-screen-cover">
            <div className="popup-card">
                <img src={fullPhotoUrl} alt="Game cover" />
                <div className="popup-filter" />

                <div className="popup-text">

                    <div className="popup-card-title">
                        <text>{Name}</text>
                    </div>
                    <div className='popup-text-content'>
                        <div className="popup-card-studio">Studio:</div>
                        <div className="popup-card-studio-name">{DevName}</div>
                        <div>
                            {tagData.map((tag, index) => (
                                <span key={index} className="popup-tag">{tag}</span>
                            ))}
                        </div>
                        <p className="popup-card-description">{Description}</p>
                    </div>
                    <p className="popup-card-description">
                        
                    </p>
                    <div className="popup-platform-logos">
                        {SteamLink && (
                            <a href={SteamLink} target="_blank" rel="noopener noreferrer">
                                <img
                                src={`${process.env.PUBLIC_URL} steamIcon.png`}
                                alt="Steam"
                                className="platform-logo"
                                style={{ width: 30, height: 30 }}
                                />
                            </a>
                        )}
                       {EpicLink && (
                            <a href={EpicLink} target="_blank" rel="noopener noreferrer">
                                <img
                                src={`${process.env.PUBLIC_URL} epicIcon.svg`}
                                alt="Epic"
                                className="platform-logo"
                                style={{ width: 30, height: 35 }}
                                />
                            </a>
                        )}
                    </div>
                </div>
                <div className="close-btn" onClick={handleClick}><img src={`${process.env.PUBLIC_URL}/xbutton.svg`} /></div>
            </div>
        </div >
    );
};

export default Popup;



{/* <div className="gameContainer">
                {images.map((image, index) => (
                <div key={index}>
                    <img
                    className={image.alt.toLowerCase().replace(' ', '')}
                    src={image.src}
                    alt={image.alt}
                    onClick={() => openPopup(image.popupSrc)}
                    />
                </div>
                ))}
            </div>

            {popupImage && (
                <div className="popup-overlay" onClick={closePopup}>
                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <img src={popupImage} alt="Popup" />
                    <div className="close-btn" onClick={closePopup}>x</div>
                </div>
                </div>
            )} */}
