// JavaScript source code
import Cookies from 'js-cookie';

export const CookiesStatus = Object.freeze({
    allCookies: 1,
    rejectCookies: 2
})

export function IsDev() {
    return process.env.NODE_ENV === "development" || Cookies.get("DevOverride") !== undefined;
}

export async function GetAccessToken() {
    if (Cookies.get("LudareAccessToken") !== undefined && Cookies.get("LudareAccessToken") !== "undefined") {
        return Cookies.get("LudareAccessToken");
    }
    if ((Cookies.get("LudareAccessToken") === undefined || Cookies.get("LudareAccessToken") !== "undefined") && Cookies.get("LudareRefreshToken") !== undefined) {
        return new Promise((resolve, reject) => {
            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'token': Cookies.get("LudareRefreshToken"), 'authType': "REFRESH_TOKEN_AUTH" })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/Login', reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success === false) {
                        resolve(undefined);
                    }

                    SetTokens(data);
                    resolve(Cookies.get("LudareAccessToken"));
                });
        });
    }
}
export function SetTokens(data) {
    Cookies.set("LudareAccessToken", data.message.accessToken, { expires: Date.parse(data.message.accessExpirationDate) });
    Cookies.set("LudareRefreshToken", data.message.refreshToken, { expires: Date.parse(data.message.refreshExpirationDate) });
}

export function CheckUsername(username, result, resultString) {
    const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'username': username })
    };

    fetch(process.env.REACT_APP_NODE_ADDRESS + '/CheckUsername', reqOptions)
        .then((res) => res.json())
        .then((data) => {
            if (data.success === false) {
                return;
            }
            result(data.result);
            if (data.result === true) {
                resultString("Username is not taken");
            }
            else {
                resultString("Username is already taken");
            }
        });
}

const specialCharacters = ['^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '-', '"', '!', '@', '#', '%', '&', '/', '\\', ',', '>', '<', "'", ':', ';', '|', '_', '~', '`', '+', '='];

export function PasswordCheck( testPassword, setLowerCheck, setCapitalCheck, setSpecialCheck, setNumberCheck, setLengthCheck) {
    let tempLength, tempCapital, tempLower, tempSpecial, tempNumber = false;
    let i;

    for (i = 0; testPassword !== null && i < testPassword.length; i++) {
        const test = testPassword.charAt(i)
        if (test >= 'a' && test <= 'z') tempLower = true;
        if (test >= 'A' && test <= 'Z') tempCapital = true;
        if (specialCharacters.includes(test)) tempSpecial = true;
        if (test >= '0' && test <= '9') tempNumber = true;
    }
    if (i >= 8) tempLength = true;
    setLowerCheck(tempLower);
    setCapitalCheck(tempCapital);
    setSpecialCheck(tempSpecial);
    setNumberCheck(tempNumber);
    setLengthCheck(tempLength);
}

export async function GetGame(gameId) {
  const req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ gameId: gameId })
    }
  try {
    const response = await fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetGameInfo', req);
    const data = await response.json();

    if (data.success) {
      console.log(data)
      return data;
    } else {
      throw new Error('Failed to retrieve game info');
    }
  } 
  catch (error) {
    console.error('Error fetching game info:', error);
    return null;
  }
}

export async function GetDeveloperInfo(developerId) {
  const req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ developerId })
    }
  try {
    const response = await fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetDeveloperInfo', req);
    const data = await response.json();

    if (data.success) {
      return data;
    } else {
      throw new Error('Failed to retrieve developer info');
    }
  } 
  catch (error) {
    console.error('Error fetching developer info:', error);
    return null;
  }
}

export async function UploadPictureLink(gameId) {
  const keyArt = `games/${gameId}/keyArt.png`
  const background = `games/${gameId}/background.png`
  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 
      gameId: gameId,
      secretKey: "J*oKUE8TytLJRaE298%-R3VHHnk#ybmtN-x9aA#HBV$hKwC1Q%PzEeHLp%a-(%9EhHJiFfBza*yQ4U-LZLCy8y_prT*YzEIIa12)",
      photoUrl: keyArt,
      backgroundUrl: background
    })
  }

  try {
    const response = await fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', req);
    const data = await response.json();
  
    console.log('Response data:', data); // Log the full response
  
    if (data.success) {
      console.log(data);
      return data;
    } else {
      throw new Error(data.message || 'Failed to update game info');
    }
  } catch (error) {
    console.error('Error updating game info:', error);
    return null;
  }  
}

export async function GetTagInfo(tagIds) {

  const idArray = tagIds.split(',');
  console.log("Array of Ids", idArray)

  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ids: idArray })
  }

  try {
    const response = await fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetTagInfo', req);
    const data = await response.json();

    // Extract the `id` values into an array
    const nameArray = data.map(tag => tag.TagName);

    console.log('Response data:', nameArray);

    return nameArray;
  }
  catch (error) {
    console.error('Error updating game info:', error);
    return null;
  }
}
